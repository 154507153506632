/* Estilos para el contenedor del modal */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Fondo semitransparente */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Asegúrate de que el modal esté sobre otros elementos */
}

/* Estilos para el contenido del modal */
.modal-content {
    background: #fff;
    padding: 2rem;
    border-radius: 8px;
    width: 80%;
    max-width: 600px;
    position: relative;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Sombra para destacar el modal */
}

/* Estilos para el botón de cerrar modal */
.close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
    cursor: pointer;
    color: #333; /* Color del ícono de cerrar */
}

/* Estilos para el contenedor de los campos del formulario en el modal */
.modal-fields {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

/* Estilos para los campos del formulario en el modal */
.modal-fields fieldset {
    border: none;
    padding: 0;
}

.modal-fields legend {
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.modal-fields input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 5px;
}

/* Estilos para el contenedor de los botones en el modal */
.modal-buttons {
    display: flex;
    justify-content: flex-end; /* Alinea los botones al final del contenedor */
    gap: 1rem; /* Espacio entre los botones */
    margin-top: 2rem; /* Espacio adicional arriba de los botones */
}

/* Botón Guardar */
.save {
    background-color: #4CAF50; /* Verde */
    color: white;
    border: none;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    cursor: pointer;
    font-size: 1rem;
}

/* Botón Cancelar */
.cancel {
    background-color: #f44336; /* Rojo */
    color: white;
    border: none;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    cursor: pointer;
    font-size: 1rem;
}

/* Estilos para mensajes de error en el modal */
.error {
    color: red;
    font-size: 0.9rem;
}

/* Estilos para la tabla */
.table-container {
    margin-top: 1rem;
}

.table {
    width: 100%;
    border-collapse: collapse;
}

.table th, .table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.table th {
    background-color: #f2f2f2;
}

.table td {
    vertical-align: middle;
}

.table .pendiente {
    background-color: yellow;
}

.table .cancelado {
    background-color: red;
}

.table .entregado {
    background-color: blue;
}

.table .pagado {
    background-color: green;
}

/* Estilos para las tarjetas de producto */
.cardsProductData {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 2rem;
    margin-top: 1rem;
}

.cardProductData {
    min-width: 18rem;
    width: calc(50% - 1rem); /* Ajuste para el espacio entre las tarjetas */
    margin-bottom: 1rem;
    padding: 1rem;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.cardProductData img {
    width: 5rem;
    height: 5rem;
    border-radius: 10px;
    object-fit: cover;
    background-color: #f2f2f2;
}

.cardProductDataText {
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.cardProductDataText h3 {
    color: #333333;
    font-weight: 500;
}

.cardProductDataText h5 {
    color: #666666;
    font-weight: 500;
    font-size: 11px;
}

.cardProductDataText span {
    color: #666666;
    font-weight: 500;
    font-size: 13px;
}

.cardProductDataText strong {
    font-weight: 500;
    color: #008000; /* Color de texto para el precio */
    font-size: 15px;
}

.cardProductDataText strong span {
    padding-left: 2rem;
    color: #008000; /* Color de texto para el precio */
    font-size: 15px;
}

@media (max-width: 900px) {
    .cardProductData {
        width: 100%;
    }
}
