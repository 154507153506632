.cardMesa{
    border-radius: 10px;
    width: 240px;
    height: 130px;
    display: flex;
    gap: 1rem;
    align-items: center;   
    flex-direction: column;
    padding: 10px;
    background-color: var(--color1);
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.114);
}
.cardMesa h3{
    color: var(--blanco);
    font-weight: 500;
}
.cardMesa h4{
    color: var(--blanco);
    font-weight: 500;
}
.deFLexBtnMesa{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.deFLexBtnMesa button{
   color: var(--blanco);

}
.bg-red {
    background-color: #F80050;
    color: var(--blanco);
}
.bg-green {
    background-color: rgb(27, 213, 27);
    color: var(--blanco);
    border: 1px solid var(--blanco);
    font-weight: 500;
}

.mesasGrap{
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    max-height: 60vh;  
    overflow: auto;
   
   
}

.mesasGrap::-webkit-scrollbar{
    width: 5px;
    height: 5px;
    cursor: pointer;
    background-color: transparent;
   
}

 
.mesasGrap::-webkit-scrollbar-thumb {
    background-color: var(--color1); 
    border-radius: 10px;
    margin-top: 3rem;
  }

  @media (max-width: 1024px){
    .cardMesa{
        border-radius: 10px;
        width: 150px;
        height: 100px;
       
    }
    .mesasGrap{
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        max-height: 60vh;  
        overflow: auto;
        justify-content: space-around;
       
    }
  }